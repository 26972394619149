// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Onboarder-primary: mat.define-palette(mat.$indigo-palette);
$Onboarder-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Onboarder-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Onboarder-theme: mat.define-light-theme(
  (
    color: (
      primary: $Onboarder-primary,
      accent: $Onboarder-accent,
      warn: $Onboarder-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Onboarder-theme);

/* Global Style Imports */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import './styles/variables.scss';
@import './styles/buttons.scss';
@import './styles/headers.scss';
@import './styles/forms.scss';
@import './styles/dialogs.scss';

html {
  height: 100%;
}

body {
  display: contents;
  margin: 0;
  font-family: $standard-font;
}

.admin-loading-bar {
  height: 0.25rem;
}
