@import './variables.scss';

/* Global Header */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 20px;
  background: $grey;

  .title {
    font-family: $standard-font;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
    padding-bottom: 1rem;
  }
}

.edges {
  padding: 0 50px 0 50px;
}
