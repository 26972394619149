/* Global Button Classes */
@import 'variables';

.btn__lg {
  display: flex;
  gap: 10px;
  align-items: center;
  color: $white;
  background: $blue;
  padding: 11px 16px;
  font-family: $standard-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 23px;
  border: none;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.btn__lg__split {
  display: flex;
  gap: 10px;
  align-items: center;
  color: $white;
  background: $blue;
  font-family: $standard-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 23px;
  cursor: pointer;

  .left-btn {
    padding: 11px 0 11px 16px;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    color: $white;
    background: $blue;
    border: none;
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .right-dropdown {
    background: $blue;
    position: relative;
    padding: 0 16px 0 11px;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    height: 46px;

    .dropdown-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      svg-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
    }

    .content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      right: 0px;

      span {
        font-family: $standard-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: $blue;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 10px;
      }

      span:hover {
        background: $dropdown-hover;
      }

      .divider {
        width: 100%;
        border-bottom: 1px solid $section-border;
      }
    }

    .open {
      display: block;
    }
  }
}

.btn__sm {
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 10px;
  color: $white;
  background: $blue;
  padding: 5px 13px;
  font-family: $standard-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.286%;
  border: none;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
  }
}

.btn__sq__sm {
  display: flex;
  gap: 5px;
  align-items: center;
  color: $white;
  background: $blue;
  font-family: $standard-font;
  padding: 5px 13px;
  border-radius: 6px;
  border: 1px solid $blue;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
  }
}

.btn__sq__sm__invrt {
  display: flex;
  gap: 5px;
  align-items: center;
  color: $blue;
  background: $white;
  font-family: $standard-font;
  padding: 5px 13px;
  border-radius: 6px;
  border: 1px solid $blue;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
  }
}

.btn__sq__md {
  color: $white;
  background: $blue;
  font-family: $standard-font;
  padding: 11px 18px;
  border-radius: 6px;
  border: 1px solid $blue;
  cursor: pointer;

  &.disabled {
    background: $disabled-text;
    border-color: $disabled-text;
    cursor: default;
  }
}

.btn__sq__md__invrt {
  color: $blue;
  background: $white;
  font-family: $standard-font;
  padding: 11px 18px;
  border-radius: 6px;
  border: 1px solid $blue;
  cursor: pointer;
}

.btn__text {
  border: none;
  background: transparent;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $standard-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
  cursor: pointer;
}
