/* Global Form Classes */
@import 'variables';

.form-section-container {
  background: $form-background;
  border-radius: 0.75rem;
  padding: 0.9375rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  h3 {
    font-family: $standard-font;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: left;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .section {
    background: $white;
    border-radius: 0.75rem;
    padding: 1.25rem;

    &--no-padding {
      padding: 0;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: $standard-font;
        font-size: 1.0625rem;
        font-weight: 600;
        line-height: 1rem;
        color: $title-blue;
        margin-top: 0;
        margin-bottom: 2.1875rem;
      }

      svg-icon {
        color: $chevron-accent;
        width: 1.125rem;
        transform: rotate(-90deg);
        transition: all 0.2s ease-in-out;

        &.open {
          transform: rotate(0deg);
        }
      }
    }

    .main {
      max-height: 0;
      opacity: 0;
      transition: max-height 0.2s ease-in-out;

      &.open {
        max-height: 100vh;
        opacity: 100%;
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
  }
}

.form-array-section-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .section {
    background: $form-background;
    border-radius: 0.625rem;
    padding: 0.75rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: $standard-font;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1rem;
        color: $title-blue;
      }

      svg-icon {
        color: $chevron-accent;
        width: 1.125rem;
        transform: rotate(-90deg);
        transition: all 0.2s ease-in-out;

        &.open {
          transform: rotate(0deg);
        }
      }
    }

    .main {
      max-height: 0;
      opacity: 0;
      transition: max-height 0.2s ease-in-out;

      &.open {
        max-height: 100vh;
        opacity: 100%;
      }
    }
  }
}

.form-layout {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.form-component-title {
  color: $title-blue;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $standard-font;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 88.889%;
  margin: 0;
}

.form-input-label {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: $standard-font;
  color: $input-text;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;

  .label-error {
    display: flex;
    gap: 0.625rem;

    .error {
      color: $red;
    }

    &.disabled {
      label {
        color: $disabled-text;
        cursor: not-allowed;
      }
    }
  }
}

.form-input {
  border-radius: 0.5rem;
  border: 0.0625rem solid $input-border;
  background: $white;
  padding: 0.625rem;
  font-family: $standard-font;
  color: $input-text;
}

::placeholder {
  color: $placeholder-text;
}

.form-input-error {
  border-color: $red;
}

.form-array-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.form-array-component {
  .form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.0625rem solid $section-border;
    border-left: 0.0625rem solid $section-border;
    border-right: 0.0625rem solid $section-border;
    border-top-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    background: $lavender;
    padding: 0.3125rem 0.9375rem;

    p {
      color: $blue;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: $standard-font;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
      margin: 0;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.9375rem;
    border-left: 0.0625rem solid $section-border;
    border-right: 0.0625rem solid $section-border;
    border-bottom: 0.0625rem solid $section-border;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
}

.disabled-form-input {
  color: $disabled-text;
  border: 0.0625rem solid $disabled-border;
  cursor: not-allowed;
}

.hidden-form-input {
  display: none;
}

.section-title {
  color: $title-blue;
  font-size: 24px;
  font-weight: 400;
  margin: 20px 0px;
}

.section-subtitle {
  color: $blue;
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
}

.form-checkbox {
  display: flex;
  user-select: none;
  gap: 8px;
  align-items: center;
  color: $input-text;
  font-weight: 600;
  position: relative;

  &--inline-flex {
    display: inline-flex;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    cursor: pointer;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    border: 2px solid $button-stroked-border;
    background-color: $fills-primary;
  }

  input[type='checkbox']:checked + label::before {
    background-color: transparent;
    border: 2px solid $light-purple;
  }

  input[type='checkbox']:checked ~ label .checkmark:after {
    display: block;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
  }

  /* When the checkbox is checked, add a transparent background */
  input[type='checkbox']:checked ~ label .checkmark {
    background-color: transparent;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input[type='checkbox']:checked ~ label .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $light-purple;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    cursor: pointer;
  }

  &.disabled {
    // input[type='checkbox'] {
    //   cursor: not-allowed;
    // }
    input[type='checkbox'] + label::before {
      content: '';
      display: inline-block;
      cursor: not-allowed;
      flex-shrink: 0;
      height: 24px;
      width: 24px;
      border-radius: 6px;
      border: 2px solid $disabled-border;
      background-color: $white;
    }

    .checkmark:after {
      left: 10px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid $disabled-text;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    label {
      cursor: not-allowed;
      color: $disabled-text;
    }
  }
}

.required-red-star {
  color: $red;
}

.form-label-value {
  font-weight: 500;
  margin: 8px 0px 8px 0px;
}

.multi-select-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  label {
    font-family: $standard-font;
    color: $input-text;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem;
  }

  .multi-select-checkbox-options {
    display: flex;
    gap: 0.625rem;
  }
}

.form-array--spacing {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
