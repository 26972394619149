/* Onbordr Colors */

$light-blue: #4666c7;
$blue: #001996;
$title-blue: #000e8f;
$sky-blue: #6479ff;
$dark-blue: #000067;
$med-blue: #b9c1e8;
$lavender: #dbdff3;
$grey: #f8f9fb;
$dark-grey: #e9e9e9;
$white: #ffffff;
$black: #000000;
$red: #fb7b7b;
$green: #15c766;
$light-purple: #6479ff;
$light-gray: #f2f2f2;
$light-green: #6fe8bd;
$route-scheme-divider: #d9deff;

$section-border: $med-blue;
$input-border: #8185a8;
$input-text: #222222;
$hover-color: #e0eaff;
$dropdown-hover: #e7e7e7;
$placeholder-text: #bababa;
$disabled-text: #888888;
$disabled-border: #f2f2f2;
$form-background: #f2f2f2;
$chevron-accent: $light-purple;
$divider: #d4daf7;

/* Onboardr Fonts */

$standard-font: 'Work Sans', sans-serif;

/* Onboardr button colors */
$button-simple-background: #fff;
$button-simple-color: #000;
$button-simple-background--selected: #f2f2f2;

$button-blue-outline-color: #1d39c4;
$button-blue-outline-background: #fff;

$button-blue-default-background: #1d39c4;
$button-blue-default-color: #fff;

$button-default-color: #fff;
$button-default-background: #3252ff;

$button-success-color: #fff;
$button-success-background: #399f80;

$button-warning-color: #fff;
$button-warning-background: #f44336;

$button-danger-color: #fff;
$button-danger-background: #f44336;

$button-stroked-border: #cacce1;
$button-stroked-color: #001996;
$button-stroked-background: #fff;
$button-stroked-border--hover: $light-purple;

$button-transparent-color: #001996;
$button-transparent-background: transparent;

$fills-primary: #f4f5f9;
